import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';

// Us
import Workspace from '@models/Workspace';
import WorkspaceButton from '@models/WorkspaceButton';
import { CustomTheme } from '@components/layout/Theme';
import IconWidget, { showIcon } from './Icon';
import TriRectangle from './TriRectangle';
import { handleWorkspaceButton, filterButton } from './implementation/workspaceHelpers';

const StyledTab = styled(Tab)(({ theme }) => ({
  '&': {
    textTransform: 'none',

    minHeight: '2rem',
    height: '2rem',
    fontWeight: 'bold',
    color: theme.palette.common.black,
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(0.5),
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:selected': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '2rem',
  height: '2rem',
}));

const StyledBox = styled(Box)(({ theme }: { theme: CustomTheme }) => ({
  backgroundColor: theme.palette.common.white, // 'white',
  color: theme.palette.common.black, // 'black',
  border: 'none',
  height: '2rem',
  overflowX: 'hidden',
  maxWidth: '100%',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

interface OwnProps {
  workspaces?: Workspace[];
}

export default function WorkspaceBar(props: React.PropsWithChildren<OwnProps>) {
  const history = useHistory();
  const { workspaces } = props;
  const workspace = workspaces?.find((ws) => ws.isActive);

  const composeButton = (button: WorkspaceButton) => {
    let el = (
      <StyledTab
        key={button.id}
        icon={(button.icon && showIcon(button.displayOptions)) ? <IconWidget icon={button.icon} /> : undefined}
        iconPosition="start"
        onClick={() => handleWorkspaceButton(button, workspace, history)}
        label={button.name}
      />
    );
    if (button.tooltip) {
      el = (
        <Tooltip key={button.id} title={button.tooltip}>
          {el}
        </Tooltip>
      );
    }
    return el;
  };

  return (
    <StyledBox style={{ display: 'flex' }}>
      <TriRectangle text={workspace?.name} icon={workspace && showIcon(workspace.displayOptions) ? workspace.icon : undefined} tooltip={workspace?.tooltip} />
      <StyledTabs variant="scrollable" scrollButtons="auto" orientation="horizontal" value={false}>
        {workspace?.children
          ?.filter((button) => filterButton(button))
          ?.map((button) => (composeButton(button)))}
      </StyledTabs>
    </StyledBox>
  );
}
