import ObjectType from '@models/ObjectType';
import FilterType from '@models/FilterType';

const filters: FilterType[] = [
  {
    id: '1',
    name: 'Business Value',
    icon: 'dataset',
    checked: false,
    typeId: ObjectType.BusinessValue,
  },
  {
    id: '2',
    name: 'Case',
    icon: 'work',
    checked: false,
    typeId: ObjectType.Case,
  },
  {
    id: '3',
    name: 'Chart',
    icon: 'leaderboard',
    checked: false,
    typeId: ObjectType.Chart,
  },
  {
    id: '4',
    name: 'Dashboard',
    icon: 'dashboard',
    checked: false,
    typeId: ObjectType.Dashboard,
  },
  {
    id: '5',
    name: 'Data Source Connection',
    icon: 'warehouse',
    checked: false,
    typeId: ObjectType.DBConnection,
  },
  {
    id: '6',
    name: 'Document',
    icon: 'description',
    checked: false,
    typeId: ObjectType.Document,
  },
  {
    id: '7',
    name: 'DropDown',
    icon: 'arrow_drop_down_cricle',
    checked: false,
    typeId: ObjectType.DropDown,
  },
  {
    id: '8',
    name: 'Folder',
    icon: 'folder',
    checked: false,
    typeId: ObjectType.Folder,
  },
  {
    id: '9',
    name: 'Form',
    icon: 'list_alt',
    checked: false,
    typeId: ObjectType.Form,

  },
  {
    id: '10',
    name: 'Form Email',
    icon: 'list_alt',
    checked: false,
    typeId: ObjectType.FormEmail,

  },
  {
    id: '11',
    name: 'Goal',
    icon: 'mode_standby',
    checked: false,
    typeId: ObjectType.Goal,

  },
  {
    id: '12',
    name: 'Knowledge View',
    icon: 'search',
    checked: false,
    typeId: ObjectType.KView,
  },
  {
    id: '13',
    name: 'Report',
    icon: 'pie_chart',
    checked: false,
    typeId: ObjectType.Report,
  },
  {
    id: '14',
    name: 'Rule',
    icon: 'filter_alt',
    checked: false,
    typeId: ObjectType.Rule,
  },
  {
    id: '15',
    name: 'Stream Action',
    icon: 'bolt',
    checked: false,
    typeId: ObjectType.StreamAction,
  },
  {
    id: '16',
    name: 'Timeline',
    icon: 'view_timeline',
    checked: false,
    typeId: ObjectType.Timeline,
  },
  {
    id: '17',
    name: 'Workflow',
    icon: 'settings',
    checked: false,
    typeId: ObjectType.Workflow,
  },
];

export default filters;
