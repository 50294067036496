/* eslint-disable react/prop-types */
// Them
import React, { useEffect, useState, useCallback } from 'react';
import {
  SnackbarContent, CustomContentProps, useSnackbar, enqueueSnackbar,
} from 'notistack';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/system/Box';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';

// Us
import { logger } from '@components/utilities/logger';
import { useLocalValue, setLocalValue, getLocalValue } from '@store/localValuesSlice';
import { getAsync } from '@components/data/rest';
import UserProfile from '@models/UserProfile';
import { reactPathTS, schemeServerBasePathTS } from '@components/utilities/Paths';
import { storeUserProfile } from '@store/userProfileSlice';
import { useAppDispatch } from '@/store/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body1,
    '& .MuiButton-outlined': {
      borderColor: 'white',
      color: 'white',
    },
    '&:hover .MuiButton-outlined': {
      borderColor: 'white',
      color: 'white',
    },
    '& .MuiButton-root': {
      '&:disabled': {
        borderColor: 'gray',
        color: 'gray',
      },
    },
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  card: {
    width: '100%',
    minHeight: '49px',
    display: 'flex',
    justifyContent: 'center',
  },
  typography: {
    ...theme.typography.body1,
    color: 'white',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: '1rem',
  },
}));

// export async function checkWebsiteStatusAsync(): Promise<boolean> {
//   const url = `${reactSchemeServerTS}images/favicon.ico`;
//   try {
//     const response = await fetch(url, {
//       method: 'HEAD',
//       cache: 'no-cache',
//       headers: {
//         'Content-Type': 'text/plain',
//       },
//     });

//     if (response.ok) {
//       logger.info('Website online and accessible');
//       return true;
//     }
//   // eslint-disable-next-line no-empty
//   } catch (error) {
//   }
//   logger.warn('Website offline or inaccessible');
//   return false;
// }

const messageKey = 'broadcast-login';

export function broadcastLogin(userProfile: UserProfile | undefined) {
  logger.debug('Broadcasting login');
  localStorage.setItem(messageKey, JSON.stringify(userProfile));

  setTimeout(() => {
    localStorage.removeItem(messageKey);
  }, 1000);
}

export function logoff() {
  setLocalValue('unmounting', true);
  window.location.href = `${schemeServerBasePathTS}/logoff.aspx`;
}

export function networkAlert(loggedOut?: boolean) {
  // eslint-disable-next-line prefer-destructuring
  const unmounting = getLocalValue<boolean>('unmounting');
  if (!unmounting) {
    return enqueueSnackbar('', {
      variant: 'networkMonitor',
      preventDuplicate: true,
      persist: true,
      key: 'networkMonitor',
      loggedOut,
    });
  }
  return '';
}

export enum NetworkStatus {
  Unknown = 0,
  Online,
  LoggedOff,
  Offline
}

export async function checkWebsiteStatusAsync(): Promise<NetworkStatus> {
  let networkStatus = NetworkStatus.Unknown;
  await getAsync('UserProfiles/CurrentUser',
    async (response) => {
      await response.obj<UserProfile>();
      networkStatus = NetworkStatus.Online;
    },
    async (errorResult) => {
      // NOTE: we return a 400 Status Code and a 401 error code to prevent the browser's default login popup if Windows Authentication is used.
      if (errorResult.httpStatusCode === 400 && errorResult.errorCode === -401) {
        networkStatus = NetworkStatus.LoggedOff;
      } else if (!errorResult.httpStatusCode) {
        networkStatus = NetworkStatus.Offline;
      }
      return true;
    });

  return networkStatus;
}

async function sleepAsync(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function toHoursMinutesSeconds(date: Date): { hours: number | undefined; minutes: number | undefined; seconds: number | undefined } {
  const diffMs = date.getTime() - Date.now();
  if (diffMs < 0) {
    return { hours: undefined, minutes: undefined, seconds: undefined };
  }
  const hours = Math.floor(diffMs / (1000 * 60 * 60));
  const minutes = Math.floor(diffMs / (1000 * 60));
  const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);

  return { hours, minutes, seconds };
}

export function DisableUX() {
  const uxBlocked = useLocalValue<boolean>('uxBlocked');

  return uxBlocked ? (
    <Modal open={!!uxBlocked} disableEnforceFocus disableAutoFocus>
      <></>
    </Modal>
  ) : null;
}

interface IProps extends CustomContentProps {
  loggedOut?: boolean;
}

const MinDelay = 30 * 1000; // 30 seconds
const MaxDelay = 60 * 60 * 1000; // 1 hour

const NetworkMonitor = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const {
    // You have access to notistack props and options 👇🏼
    id,
    message: _ignored,
    className,
    variant,
    // as well as your own custom props 👇🏼
    loggedOut: loggedOutMode,
    // Ignored...(to prevent errors when spreading props to the SnackbarContent component)
    action,
    persist,
    anchorOrigin,
    autoHideDuration,
    hideIconVariant,
    iconVariant,
    // Remaining props
    ...other
  } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const [delay, setDelay] = useState(MinDelay);
  const [startTime, setStartTime] = React.useState(new Date(Date.now() + delay));
  const [timeLeft, setTimeLeft] = useState<string>('');
  const [loggedOut, setLoggedOut] = useState<boolean>(!!loggedOutMode);
  const [message, setMessage] = useState<string>('');
  const [busy, setBusy] = useState<boolean>(false);

  useEffect(() => {
    if (loggedOut) {
      setMessage('You are no longer logged in. Click Login to continue.');
    } else {
      setMessage('Server communication failed. Either the network is disconnected or the server is offline.');
    }
  }, [loggedOut]);

  const reconnectNow = useCallback(async (newDelay?: number, jitter?: number) => {
    setTimeLeft(' Reconnecting...');
    setBusy(true);
    const checkResult = await checkWebsiteStatusAsync();
    setBusy(false);
    switch (checkResult) {
      case NetworkStatus.Online: {
        closeSnackbar(id);
        enqueueSnackbar('Server communication restored', { variant: 'success', preventDuplicate: true });
        setLocalValue('uxBlocked', false);
        // eslint-disable-next-line no-param-reassign
        jitter = jitter !== undefined ? jitter : Math.random() * (60 * 1000);
        logger.info(`Server communication restored. Delay of ${jitter}ms before refreshing local data.`);
        await sleepAsync(jitter);
        setLocalValue('refreshSignalR', true);
        setLocalValue('refreshData', true);
        setLoggedOut(false);
        break;
      }
      case NetworkStatus.LoggedOff:
        logger.info('User is not logged in.');
        setLoggedOut(true);
        break;
      default:
        // eslint-disable-next-line no-param-reassign
        newDelay = newDelay || Math.min(delay * 2, MaxDelay);
        setDelay(newDelay);
        setStartTime(new Date(Date.now() + newDelay));
        setLoggedOut(false);
        break;
    }
  }, [closeSnackbar, delay, id]);

  useEffect(() => {
    let interval: number | undefined;
    if (!loggedOut && !busy) {
      interval = window.setInterval(() => {
        const { hours, minutes, seconds } = toHoursMinutesSeconds(startTime);
        if (seconds === undefined) {
          reconnectNow();
          clearInterval(interval);
          return;
        }
        if (hours! > 0) {
          setTimeLeft(` Attempting reconnect in ${hours} hours ${minutes} minutes ${seconds} seconds`);
        } else if (minutes! > 0) {
          setTimeLeft(` Attempting reconnect in ${minutes} minutes ${seconds} seconds`);
        } else {
          setTimeLeft(` Attempting reconnect in ${seconds} seconds`);
        }
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [busy, loggedOut, reconnectNow, startTime]);

  // Only run once, when component is mounted.
  useEffect(() => {
    setLocalValue('uxBlocked', true);
  }, []);

  useEffect(() => {
    if (startTime.getTime() < Date.now()) {
      reconnectNow(delay);
    }
  }, [delay, reconnectNow, startTime]);

  useEffect(() => {
    let interval: number | undefined;
    if (loggedOut) {
      interval = window.setInterval(() => {
        const data = localStorage.getItem(messageKey);
        if (data) {
          logger.debug('User logged in');
          clearInterval(interval);
          interval = undefined;
          const userProfile = JSON.parse(data) as UserProfile;
          storeUserProfile(dispatch, userProfile);
          closeSnackbar(id);
          reconnectNow(undefined, 0);
        }
      }, 250);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [closeSnackbar, dispatch, id, loggedOut, reconnectNow]);

  const handleLogin = () => {
    window.location.replace(`${reactPathTS}../login.aspx`);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SnackbarContent ref={ref} role="alert" className={clsx(className, classes.root)} {...other}>
      <Card className={classes.card} sx={{ backgroundColor: 'red' }}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <CancelIcon sx={{ color: 'white', margin: '0 0.5rem' }} fontSize="small" />
          <Typography className={classes.typography}>
            {loggedOut ? message : message + timeLeft}
          </Typography>
          <Box className={classes.icons}>
            {!loggedOut && ( // && (startTime.getTime() - Date.now() > 0)
              <Box sx={{ position: 'relative' }}>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={busy}
                  onClick={() => reconnectNow()}
                >
                  Reconnect Now
                </Button>
                {busy && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            )}
            {loggedOut && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleLogin()}
              >
                Login
              </Button>
            )}
          </Box>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

export default NetworkMonitor;
