/* eslint-disable no-bitwise */
/// <reference path="../data/signalr-bp.ts" />
// Them
import React, {
  useState, useEffect, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';

// Us
import ProfileMenu from '@components/layout/ProfileMenu';
import ContentList from '@components/layout/ContentList';
import { useAppDispatch } from '@store/hooks';
import {
  storeSystemFolderState, useContentList, useIncludeSystemFolders,
} from '@store/contentListSlice';
import { useUserProfile } from '@store/userProfileSlice';
import { useWorkspaces } from '@store/workspacesSlice';
import { useProduct } from '@/store/productSlice';
import { reactPath } from '@components/utilities/Paths';
import { ContentListWidgetStateContext } from '@components/layout/ContentListWidgetState';
import { CustomTheme } from '@components/layout/Theme';
import { StyledAvatarButton } from './StyledButtons';
import TopNav from './TopNav';
import WorkspaceBar from './WorkspaceBar';

const useStyles = makeStyles((customTheme: CustomTheme) => ({
  root: {
    display: 'flex',
  },
  appBarChild: {
    flex: '1 1 0%',
    display: 'grid',
    gridTemplateColumns: '120px 1fr 100px',
  },
  appBarLogo: {
    margin: '.5rem',
    maxHeight: '44px',
    maxWidth: '105px',
  },
  appBarMenu: {
    display: 'flex',
    marginLeft: '1rem',
  },
  appBarUserProfile: {
    justifySelf: 'end',
  },
}));

export default function Layout() {
  const dispatch = useAppDispatch();
  const contentList = useContentList();
  const workspaces = useWorkspaces();
  const includeSystemFolders = useIncludeSystemFolders();
  const userProfile = useUserProfile();
  const history = useHistory();
  const location = useLocation();
  const product = useProduct();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (contentList?.length && location.pathname === '/') {
      history.replace(`/if${contentList[0].url}`);
    }
  }, [location, history, contentList]);

  const handleUserAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget); };
  const handleProfileMenuClose = () => { setAnchorEl(null); };

  const toggleSystemFolders = () => {
    dispatch(storeSystemFolderState(!includeSystemFolders));
  };

  const {
    open, pinned, setOpen,
  } = useContext(ContentListWidgetStateContext);

  const handleContentListClick = () => {
    if (!pinned) {
      setOpen(!open);
    }
  };

  const getUserName = (): string => {
    if (userProfile?.userName && userProfile.userId) {
      return `${userProfile.userName} (${userProfile.userId})`;
    }
    if (userProfile?.userName) {
      return userProfile.userId;
    }
    if (!userProfile?.userName && userProfile?.userId) {
      return userProfile.userId;
    }
    return '(Not available)';
  };

  return (
    <>
      <AppBar
        position="fixed"
      >
        <div className={classes.appBarChild}>
          {product && (
            <a href={product.logoLink || '#'} target="_blank" rel="noopener noreferrer">
              <img
                src={product.logoURL || `${reactPath}/bp-logix-logo.svg`}
                alt="BP Logix"
                className={classes.appBarLogo}
              />
            </a>
          )}
          <TopNav
            className={classes.appBarMenu}
            workspaces={workspaces}
            contentListToggleState={open && !pinned}
            onContentListClick={handleContentListClick}
            userProfile={userProfile}
          />
          {userProfile ? (
            <>
              <StyledAvatarButton
                classes={{ root: classes.appBarUserProfile }}
                onClick={handleUserAvatarClick}
                id="UserProfile"
              >
                <Tooltip title={getUserName()}>
                  <Avatar
                    alt={getUserName()}
                    src={userProfile.imageUrl}
                  />
                </Tooltip>
              </StyledAvatarButton>
              <ProfileMenu anchorEl={anchorEl} open={!!anchorEl} onClose={handleProfileMenuClose} userProfile={userProfile} workspaces={workspaces} />
            </>
          ) : (<Skeleton variant="circular" width={40} height={40} />)}
        </div>
        <WorkspaceBar workspaces={workspaces} />
      </AppBar>
      <div>
        <ContentList
          contentList={contentList}
          includeSystemFolders={includeSystemFolders}
          toggleSystemFolders={toggleSystemFolders}
        />
      </div>
    </>
  );
}
