/* eslint-disable no-console */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-redeclare */ // ESLint isn't properly understanding overloaded functions.

// Them
import React from 'react';
import Button from '@mui/material/Button';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

// Us
// eslint-disable-next-line import/no-cycle
import { basePath } from '@components/utilities/Paths';
// eslint-disable-next-line import/no-cycle

interface IErrorResult {
  errorMessage: string;
  errorCode: number;
  exceptionMessage?: string;
  exceptionType?: string;
  stackTrace?: string;
}

/**
 * Provides REST-specific information for REST API failures. Extends @see Error.
 * Requires server-side to use consistent model for JSON it returns for failures
 * outside the 500-class status codes (internal server errors).
 */
export class ErrorResult extends Error implements IErrorResult {
  /**
   * Description of the error provided by server-side API. Not necessarily
   * user friendly.
   */
  public readonly errorMessage: string;

  /**
   * Unique value provided by server-side API to identify the error. Can be used
   * to map to user-friendly error messages, if needed.
   */
  public readonly errorCode: number;

  public readonly exceptionMessage?: string;

  public readonly exceptionType?: string;

  public readonly stackTrace?: string;

  /**
   * HTTP status code from server-side API.
   */
  public readonly httpStatusCode: number;

  constructor(errorResult: IErrorResult, httpStatusCode?: number, message?: string) {
    super(message || errorResult.errorMessage);
    this.errorMessage = errorResult.errorMessage;
    this.errorCode = errorResult.errorCode;
    this.exceptionMessage = errorResult?.exceptionMessage;
    this.exceptionType = errorResult?.exceptionType;
    this.stackTrace = errorResult?.stackTrace;
    this.httpStatusCode = httpStatusCode !== undefined ? httpStatusCode : 0;
  }

  public log() {
    logError(undefined, { error: this });
  }
}

/**
 * Represents the response context from a REST request/response sequence.
 */
export class RestResponse {
  public response: Response;

  constructor(response: Response) {
    this.response = response;
  }

  public async obj<T>(): Promise<T> {
    try {
      return (await this.response.json()) as T;
    } catch {
      throw new ErrorResult(
        { errorMessage: 'Failured communicating with server.', errorCode: 0 },
        undefined,
        `Failure retrieving payload (${this.response.type}:${this.response.url}).`,
      );
    }
  }
}

/**
 * Provide well-formed @see ErrorResult from REST APIs, wrapping @see fetch.
 */
export class RestRequest {
  public ignoreFailures: boolean = false;

  private readonly options = {
    route: '',
    accept: 'application/json',
    contentType: 'application/json',
  };

  constructor(route: string, ignoreFailures?: boolean) {
    this.options.route = route;
    this.ignoreFailures = !!ignoreFailures;
  }

  public async getAsync(payload?: any, ignoreFailures?: boolean): Promise<RestResponse> {
    const encodeParams = (p: object | string | boolean | number | symbol) => {
      Object.entries(p).map((keyValues) => keyValues.map(encodeURIComponent).join('=')).join('&');
    };
    if (payload !== undefined) {
      this.options.route += `?${encodeParams(payload)}`;
    }
    return this.fetchAsync('GET', undefined, ignoreFailures);
  }

  public async putAsync(data?: any, ignoreFailure?: boolean): Promise<RestResponse> {
    return this.fetchAsync('PUT', data, ignoreFailure);
  }

  public async postAsync(data?: any, ignoreFailure?: boolean): Promise<RestResponse> {
    return this.fetchAsync('POST', data, ignoreFailure);
  }

  public async deleteAsync(data?: any, ignoreFailure?: boolean): Promise<RestResponse> {
    return this.fetchAsync('DELETE', data, ignoreFailure);
  }

  public async patchAsync(data?: any, ignoreFailure?: boolean): Promise<RestResponse> {
    return this.fetchAsync('PATCH', data, ignoreFailure);
  }

  public async restAsync(
    requestFn: (request: RestRequest) => Promise<RestResponse>,
    responseFn?: (response: RestResponse) => Promise<void>,
    errorFn?: (errorResult: ErrorResult) => Promise<boolean>,
    finallyFn?: () => Promise<void>,
  ): Promise<void> {
    try {
      const response = await requestFn(this);
      if (responseFn) {
        await responseFn(response);
      }
    } catch (e) {
      if (this.ignoreFailures) {
        return;
      }
      const error = e as ErrorResult | Error | any;
      // Add fields so this feels like ErrorResult.
      if (!('errorCode' in error)) {
        (error as any).errorMessage = error.message ? error.message : '';
        (error as any).errorCode = 0;
        (error as any).httpStatusCode = 0;
      }
      // We must always give client-supplied errorFn the first chance to handle an error.
      // We didn't used to do this for 500 class errors, but that's not right and could
      // leave some client code in a "hung" (loading) state instead of dropping into an error state.
      try {
        if (await errorFn?.(error as ErrorResult)) {
          return;
        }
      } catch {
        //
      }
      // Be careful trying to re-organize or remove any of the following code. It's designed such that
      // if user's report any of these errors we have a better idea of what happened:
      //   - Unexpected server error (400-499): The API returned a known error but it's not handled client-side.
      //   - Internal server error (500+): Either the API failed internally with an unhandled exception or some
      //     network-related configuration issue arose (bad gateway, proxy issues, etc.). See MDN pages like this
      //     one: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/503?utm_source=mozilla&utm_medium=devtools-netmonitor&utm_campaign=default
      //   - Server communication failed: Network/connectivity issues or bad options to fetch. See below.
      if (error.httpStatusCode !== undefined && error.httpStatusCode >= 400 && error.httpStatusCode < 500) {
        // Ensure this message is different from below to aid in debugging.
        // NOTE: we return a 400 Status Code and a 401 error code to prevent the browser's default login popup if Windows Authentication is used.
        this.reportComError('Unexpected server error', { error, loggedOut: error.httpStatusCode === 400 && error.errorCode === -401 });
        return;
      }
      if (error.httpStatusCode && error.httpStatusCode >= 500) {
        this.reportComError('Internal server error', { error });
        return;
      }
      // See MDN https://developer.mozilla.org/en-US/docs/Web/API/fetch for exceptions that could land you here.
      // It's primarily TypeError but with many possible reasons, only one case involves network errors but it's
      // probably the most probable for how we use the API.
      this.reportComError('Server communication failed', { error, offline: true });
    } finally {
      finallyFn?.();
    }
  }

  private reportComError(
    friendlyMessage: string,
    {
      details, error, offline, loggedOut,
    }: { details?: string; error?: Error; offline?: boolean, loggedOut?: boolean },
  ) {
    if (this.ignoreFailures) {
      return;
    }
    try {
      logError(details, { friendlyMessage, error });
      enqueueSnackbar(friendlyMessage, {
        variant: offline || loggedOut ? 'networkMonitor' : 'error',
        preventDuplicate: true,
        persist: true,
        loggedOut,
        action: (key) => (offline ? (
          undefined
        ) : (
          <Button size="small" style={{ color: 'white' }} variant="outlined" onClick={() => closeSnackbar(key)}>
            Dismiss
          </Button>
        )),
      });
    } catch {
      console.error('Failed reporting error.');
    }
  }

  private async fetchAsync(
    httpMethod: string, payload: any, ignoreFailures?: boolean,
  ): Promise<RestResponse> {
    if (ignoreFailures) {
      this.ignoreFailures = ignoreFailures;
    }
    const rawResponse = await fetch(`${basePath}api/${this.options.route}`, {
      method: httpMethod,
      credentials: 'same-origin',
      headers: {
        Accept: this.options.accept,
        ...(!(payload instanceof FormData) && { 'Content-Type': this.options.contentType }),
      },
      // eslint-disable-next-line no-nested-ternary
      body: payload !== undefined ? ((payload instanceof FormData) ? payload : JSON.stringify(payload)) : undefined,
    });
    if (rawResponse.ok) {
      return new RestResponse(rawResponse);
    }

    let obj = {};
    try {
      obj = await rawResponse.json();
    } catch (e) {
      if (this.ignoreFailures) {
        logError('Ignoring failure', { error: e });
        return new RestResponse(rawResponse);
      }
      throw new ErrorResult(
        {
          errorMessage: 'Failure communicating with server.',
          errorCode: 0,
        } as IErrorResult,
        rawResponse.status,
        `Failure communicating with server (${httpMethod}:${this.options.route}).`,
      );
    }

    const errorResult = new ErrorResult(obj as IErrorResult, rawResponse.status);
    if (this.ignoreFailures) {
      logError('Ignoring failure', { error: errorResult });
      return new RestResponse(rawResponse);
    }
    throw errorResult;
  }
}

/**
 * Common options to all REST requests.
 */
export interface RestOptions {
  /**
   * (required) Path on server to REST action (endpoint). "api/" is automatically prepended.
   */
  route: string;

  /**
   * (optional) If you have JSON data to send, specify it here. The object is stringified for you,
   * except for GET HTTP methods, where it is converted to URL params. The GET URL param processing
   * is naive and won't handle much more than simple numbers and un-escaped strings.
   */
  data?: any;

  /**
   * (optional) On occasion, you don't care about any failure. Set this to true if that's the case.
   */
  ignoreFailures?: boolean;
}

//-------------------------------------------------------------------------------------------------
//                                    HTTP GET REST API
//-------------------------------------------------------------------------------------------------

/**
 * Performs an asynchronous HTTP GET request with error handling.
 * @param route The URI of the REST method to call. "api/" is automatically prepended.
 * @param options RestOptions that specify the route, any data (payload) to send,
 * and whether to ignore failures.
 * @param responseFn Lambda (arrow function) to optionally process a response.
 * @param errorFn Lambda (arrow function) to optionally handle expected failures. true for handled.
 * false to continue processing.
 * @param finallyFn Lambda (arrow function) to optionally execute regardless of the outcome.
 */
export async function getAsync(
  route: string,
  responseFn?: (response: RestResponse) => Promise<void>,
  errorFn?: (errorResult: ErrorResult) => Promise<boolean>,
  finallyFn?: () => Promise<void>
): Promise<void>;

export async function getAsync(
  options: RestOptions,
  responseFn?: (response: RestResponse) => Promise<void>,
  errorFn?: (errorResult: ErrorResult) => Promise<boolean>,
  finallyFn?: () => Promise<void>
): Promise<void>;

export async function getAsync(
  routeOrOptions: string | RestOptions,
  responseFn?: (response: RestResponse) => Promise<void>,
  errorFn?: (errorResult: ErrorResult) => Promise<boolean>,
  finallyFn?: () => Promise<void>,
): Promise<void> {
  const data: any | undefined = typeof routeOrOptions === 'object' ? routeOrOptions.data : undefined;
  const fn = async (request: RestRequest): Promise<RestResponse> => request.getAsync(data);
  await restAsync(routeOrOptions, fn, responseFn, errorFn, finallyFn);
}

//-------------------------------------------------------------------------------------------------
//                                    HTTP POST REST API
//-------------------------------------------------------------------------------------------------

export async function postAsync(
  route: string,
  responseFn?: (response: RestResponse) => Promise<void>,
  apiErrorFn?: (apiError: ErrorResult) => Promise<boolean>,
  apiFinallyFn?: () => Promise<void>
): Promise<void>;

export async function postAsync(
  options: RestOptions,
  responseFn?: (response: RestResponse) => Promise<void>,
  apiErrorFn?: (apiError: ErrorResult) => Promise<boolean>,
  apiFinallyFn?: () => Promise<void>
): Promise<void>;

/**
 * Performs an asynchronous HTTP POST request with error handling.
 * @param route The URI of the REST method to call. "api/" is automatically prepended.
 * @param options RestOptions that specify the route, any data (payload) to send,
 * and whether to ignore failures.
 * @param responseFn Lambda (arrow function) to optionally process a response.
 * @param apiErrorFn Lambda (arrow function) to optionally handle failures. true for handled. false to continue processing.
 * @param apiFinallyFn Lambda (arrow function) to optionally execute regardless of the outcome.
 */
export async function postAsync(
  routeOrOptions: string | RestOptions,
  responseFn?: (response: RestResponse) => Promise<void>,
  apiErrorFn?: (apiError: ErrorResult) => Promise<boolean>,
  apiFinallyFn?: () => Promise<void>,
): Promise<void> {
  const data: any | undefined = typeof routeOrOptions === 'object' ? routeOrOptions.data : undefined;
  const fn = async (request: RestRequest): Promise<RestResponse> => request.postAsync(data);
  await restAsync(routeOrOptions, fn, responseFn, apiErrorFn, apiFinallyFn);
}

//-------------------------------------------------------------------------------------------------
//                                    HTTP PUT REST API
//-------------------------------------------------------------------------------------------------

export async function putAsync(
  route: string,
  responseFn?: (response: RestResponse) => Promise<void>,
  apiErrorFn?: (apiError: ErrorResult) => Promise<boolean>,
  apiFinallyFn?: () => Promise<void>
): Promise<void>;

export async function putAsync(
  options: RestOptions,
  responseFn?: (response: RestResponse) => Promise<void>,
  apiErrorFn?: (apiError: ErrorResult) => Promise<boolean>,
  apiFinallyFn?: () => Promise<void>
): Promise<void>;

/**
 * Performs an asynchronous HTTP PUT request with error handling.
 * @param route The URI of the REST method to call. "api/" is automatically prepended.
 * @param options RestOptions that specify the route, any data (payload) to send,
 * and whether to ignore failures.
 * @param responseFn Lambda (arrow function) to optionally process a response.
 * @param apiErrorFn Lambda (arrow function) to optionally handle failures. true for handled. false to continue processing.
 * @param apiFinallyFn Lambda (arrow function) to optionally execute regardless of the outcome.
 */
export async function putAsync(
  routeOrOptions: string | RestOptions,
  responseFn?: (response: RestResponse) => Promise<void>,
  apiErrorFn?: (apiError: ErrorResult) => Promise<boolean>,
  apiFinallyFn?: () => Promise<void>,
): Promise<void> {
  const data: any | undefined = typeof routeOrOptions === 'object' ? routeOrOptions.data : undefined;
  const fn = async (request: RestRequest): Promise<RestResponse> => request.putAsync(data);
  await restAsync(routeOrOptions, fn, responseFn, apiErrorFn, apiFinallyFn);
}

//-------------------------------------------------------------------------------------------------
//                                    HTTP DELETE REST API
//-------------------------------------------------------------------------------------------------

export async function deleteAsync(
  route: string,
  responseFn?: (response: RestResponse) => Promise<void>,
  apiErrorFn?: (apiError: ErrorResult) => Promise<boolean>,
  apiFinallyFn?: () => Promise<void>
): Promise<void>;

export async function deleteAsync(
  options: RestOptions,
  responseFn?: (response: RestResponse) => Promise<void>,
  apiErrorFn?: (apiError: ErrorResult) => Promise<boolean>,
  apiFinallyFn?: () => Promise<void>
): Promise<void>;

/**
 * Performs an asynchronous HTTP DELETE request with error handling.
 * @param route The URI of the REST method to call. "api/" is automatically prepended.
 * @param options RestOptions that specify the route, any data (payload) to send,
 * and whether to ignore failures.
 * @param responseFn Lambda (arrow function) to optionally process a response.
 * @param apiErrorFn Lambda (arrow function) to optionally handle failures. true for handled. false to continue processing.
 * @param apiFinallyFn Lambda (arrow function) to optionally execute regardless of the outcome.
 */
export async function deleteAsync(
  routeOrOptions: string | RestOptions,
  responseFn?: (response: RestResponse) => Promise<void>,
  apiErrorFn?: (apiError: ErrorResult) => Promise<boolean>,
  apiFinallyFn?: () => Promise<void>,
): Promise<void> {
  const data: any | undefined = typeof routeOrOptions === 'object' ? routeOrOptions.data : undefined;
  const fn = async (request: RestRequest): Promise<RestResponse> => request.deleteAsync(data);
  await restAsync(routeOrOptions, fn, responseFn, apiErrorFn, apiFinallyFn);
}

//-------------------------------------------------------------------------------------------------
//                                    HTTP PATCH REST API
//-------------------------------------------------------------------------------------------------

export async function patchAsync(
  route: string,
  responseFn?: (response: RestResponse) => Promise<void>,
  apiErrorFn?: (apiError: ErrorResult) => Promise<boolean>,
  apiFinallyFn?: () => Promise<void>
): Promise<void>;

export async function patchAsync(
  options: RestOptions,
  responseFn?: (response: RestResponse) => Promise<void>,
  apiErrorFn?: (apiError: ErrorResult) => Promise<boolean>,
  apiFinallyFn?: () => Promise<void>
): Promise<void>;

/**
 * Performs an asynchronous HTTP PATCH request with error handling.
 * @param route The URI of the REST method to call. "api/" is automatically prepended.
 * @param options RestOptions that specify the route, any data (payload) to send,
 * and whether to ignore failures.
 * @param responseFn Lambda (arrow function) to optionally process a response.
 * @param apiErrorFn Lambda (arrow function) to optionally handle failures. true for handled. false to continue processing.
 * @param apiFinallyFn Lambda (arrow function) to optionally execute regardless of the outcome.
 */
export async function patchAsync(
  routeOrOptions: string | RestOptions,
  responseFn?: (response: RestResponse) => Promise<void>,
  apiErrorFn?: (apiError: ErrorResult) => Promise<boolean>,
  apiFinallyFn?: () => Promise<void>,
): Promise<void> {
  const data: any | undefined = typeof routeOrOptions === 'object' ? routeOrOptions.data : undefined;
  const fn = async (request: RestRequest): Promise<RestResponse> => request.patchAsync(data);
  await restAsync(routeOrOptions, fn, responseFn, apiErrorFn, apiFinallyFn);
}

//-------------------------------------------------------------------------------------------------
//                                         Internals
//-------------------------------------------------------------------------------------------------

function logError(
  errorMessage: string | undefined,
  { friendlyMessage, error }: {friendlyMessage?: string; error?: Error },
) {
  const friendlyMsg = friendlyMessage ? `\n\tUser message for error: ${friendlyMessage}` : '';
  let errorLog = `Communication error${friendlyMsg}\n\t`;
  if (errorMessage) {
    errorLog += `Details: ${errorMessage}`;
  } else if (error) {
    const errorResult = error as ErrorResult;
    if ('errorCode' in error) {
      errorLog += `Error: ${errorResult.message}\n\terrorResult: ${errorResult.errorMessage}\n\t`
        + `errorCode: ${errorResult.errorCode}\n\tHTTP status code: ${errorResult.httpStatusCode}`;
    } else {
      errorLog += `Error: ${errorResult.message}`;
    }
    if ('exceptionMessage' in error && errorResult.exceptionMessage !== undefined) {
      errorLog += `\n\tException message: ${errorResult.exceptionMessage}`;
    }
    if ('exceptionType' in error && errorResult.exceptionType !== undefined) {
      errorLog += `\n\tException type: ${errorResult.exceptionType}`;
    }
    if ('stackTrace' in error && errorResult.stackTrace !== undefined) {
      errorLog += '\n\tException stack trace:\n';
      errorResult.stackTrace.split('\n').forEach((line) => { errorLog += `\t${line}\n`; });
    }
  } else {
    errorLog += 'No details available';
  }
  console.error(errorLog);
}

async function restAsync(
  routeOrOptions: string | RestOptions,
  requestFn: (request: RestRequest) => Promise<RestResponse>,
  responseFn?: (response: RestResponse) => Promise<void>,
  errorFn?: (errorResult: ErrorResult) => Promise<boolean>,
  finallyFn?: () => Promise<void>,
): Promise<void> {
  const route = typeof routeOrOptions === 'string' ? routeOrOptions : routeOrOptions.route;
  const ignoreFailures = typeof routeOrOptions !== 'string' ? !!routeOrOptions.ignoreFailures : false;
  const request = new RestRequest(route, ignoreFailures);
  return request.restAsync(requestFn, responseFn, errorFn, finallyFn);
}
