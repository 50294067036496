// Them
import { compose, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory, History } from 'history';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';

// Us
// eslint-disable-next-line import/no-cycle
import contentListReducer from '@store/contentListSlice';
// eslint-disable-next-line import/no-cycle
import workspacesReducer from '@store/workspacesSlice';
// eslint-disable-next-line import/no-cycle
import resourcesReducer from '@store/resourcesSlice';
// eslint-disable-next-line import/no-cycle
import userProfileReducer from '@store/userProfileSlice';
// eslint-disable-next-line import/no-cycle
import productReducer from '@store/productSlice';
// eslint-disable-next-line import/no-cycle
import localValuesReducer from '@store/localValuesSlice';

export const history = createBrowserHistory();

const createRootReducer = (hist: History) => combineReducers({
  router: connectRouter(hist),
  contentList: contentListReducer,
  workspaces: workspacesReducer,
  resources: resourcesReducer,
  userProfile: userProfileReducer,
  product: productReducer,
  localValues: localValuesReducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage('processDirector'),
  blacklist: ['router', 'localValues'],
};

const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: {},
  enhancers: compose(applyMiddleware(routerMiddleware(history), thunk)) as any,
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
