// Them
import React from 'react';
import { History } from 'history';
import Button from '@mui/material/Button';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

// Us
import Workspace from '@models/Workspace';
import WorkspaceButtonType from '@models/WorkspaceButtonType';
import WorkspaceButton from '@models/WorkspaceButton';
import KViewNavType from '@models/KViewNavType';
import { IContentFrame, IDesktopItem, navWorkspace } from '@components/utilities/Navigation';
import { getIconData } from '../Icon';

function OpenInTab(url: string) {
  const win = window.open(url, '_blank');
  if (win) {
    win.focus();
  } else {
    enqueueSnackbar('Failed to open window. Check browser popup settings.', {
      variant: 'error',
      preventDuplicate: true,
      persist: true,
      action: (key) => (
        <Button size="small" style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
          Dismiss
        </Button>
      ),
    });
  }
}

function redirectToDesktop(workspace: Workspace | undefined, pathname: string, history: History<unknown>, callback: () => void) {
  if (workspace?.isDesktop && history.location.pathname !== pathname) {
    // Don't update history, replace it. Otherwise, the back button history will be messed up.
    history.replace(pathname);
    setTimeout(() => redirectToDesktop(workspace, pathname, history, callback), 100);
  } else {
    callback();
  }
}

const openHtml = (button: WorkspaceButton, workspace: Workspace | undefined, bpWindow: IContentFrame | null) => {
  if (bpWindow?.openUrl) {
    const icon = getIconData(button.icon);
    const desktopItem: IDesktopItem = {
      Height: -1,
      HoverTitle: false,
      HTMLContent: button.data as string,
      ItemAction: 16,
      ItemID: button.id,
      ItemIcon: icon?.id || 0,
      ItemIconColor: icon?.color || '',
      ItemName: button.name,
      Left: -1,
      Tooltip: '',
      Top: -1,
      Width: -1,
      WindowState: 0,
    };
    bpWindow.openUrl(desktopItem);
  } else if (workspace?.isDesktop) {
    enqueueSnackbar(`Return to '${workspace?.name}' workspace to display content.`, {
      variant: 'error',
      preventDuplicate: true,
      persist: true,
      action: (key) => (
        <Button size="small" style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
          Dismiss
        </Button>
      ),
    });
  }
};

export const handleWorkspaceButton = (button: WorkspaceButton, workspace: Workspace | undefined, history: History<unknown>) => {
  const iframe = document.querySelector('iframe[title="content"]') as HTMLIFrameElement;
  const bpWindow = (iframe?.contentWindow as unknown as IContentFrame | null);
  let { url } = button;
  // Leading extra slashes impacts certain pop-up windows.
  while (url.startsWith('//')) {
    url = url.substring(1);
  }

  // Check if we are not in the desktop.aspx
  if (workspace?.isDesktop && history.location.pathname !== '/if/desktop.aspx') {
    // If we are not, then navigate to desktop.aspx and then navigate to the url.
    navWorkspace(history, '/desktop.aspx', button.name, { id: button.id, icon: button.icon, checkFormCase: true });
  }

  switch (WorkspaceButtonType[button.type.toString() as keyof typeof WorkspaceButtonType]) {
    case WorkspaceButtonType.Home:
      navWorkspace(history, button.url, button.name, { id: button.id, icon: button.icon, useContentFrame: true });
      break;
    case WorkspaceButtonType.Url:
    case WorkspaceButtonType.UrlTop: // No longer used in product.
    case WorkspaceButtonType.KView:
    case WorkspaceButtonType.GlobalKView:
    case WorkspaceButtonType.PartitionAdmin:
    case WorkspaceButtonType.ITAdmin:
    case WorkspaceButtonType.UserProfile:
    case WorkspaceButtonType.eForm:
    case WorkspaceButtonType.Report:
    case WorkspaceButtonType.Chart:
    case WorkspaceButtonType.eFormInstance:
    case WorkspaceButtonType.Dashboard:
    case WorkspaceButtonType.ApplicationRun:
    case WorkspaceButtonType.ApplicationModify:
    case WorkspaceButtonType.CloseCaseWorkspaces: // This may need special handling since formerly used iNavigateTop().
    case WorkspaceButtonType.ApplicationCreate:
      redirectToDesktop(workspace, '/if/desktop.aspx', history, () => {
        navWorkspace(history, url, button.name, { id: button.id, icon: button.icon, checkFormCase: true }, workspace?.isDesktop);
      });
      break;
    case WorkspaceButtonType.HTML: // Only valid for desktop.aspx
      openHtml(button, workspace, bpWindow);
      break;
    case WorkspaceButtonType.DataFlowAnalyzer:
      if (workspace?.isDesktop) {
        redirectToDesktop(workspace, '/if/desktop.aspx', history, () => {
          navWorkspace(history, url, button.name, { id: button.id, icon: button.icon }, workspace?.isDesktop);
        });
      } else {
        OpenInTab(url);
      }
      break;
    case WorkspaceButtonType.JavaScript:
      bpWindow?.eval(url);
      break;
    case WorkspaceButtonType.None:
    case WorkspaceButtonType.Logoff: // Logoff not handled here because it is handled in the Layout component.
    default:
  }
};

const FilteredButtonTypes = [WorkspaceButtonType.Logoff, WorkspaceButtonType.ITAdmin, WorkspaceButtonType.PartitionAdmin, WorkspaceButtonType.UserProfile];

export const filterButton = (button: WorkspaceButton): boolean => {
  if (button && button.type && (FilteredButtonTypes.includes(WorkspaceButtonType[button.type.toString() as keyof typeof WorkspaceButtonType])
    || (WorkspaceButtonType[button.type.toString() as keyof typeof WorkspaceButtonType] === WorkspaceButtonType.Url && button.url.toLowerCase().includes('/admin/impersonate.aspx')))) {
    return false;
  }
  if (button && button.type && (WorkspaceButtonType[button.type.toString() as keyof typeof WorkspaceButtonType] === WorkspaceButtonType.GlobalKView && button.data)) {
    const navType = button.data as KViewNavType;
    return KViewNavType[navType.toString() as keyof typeof KViewNavType] !== KViewNavType.NavFolder;
  }
  return true;
};
