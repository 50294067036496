// Them
import { useEffect } from 'react';

// Us
import { useAppDispatch } from '@store/hooks';
import { getContentList, useContentList } from '@store/contentListSlice';
import { getUserProfileAsync, useUserProfile } from '@store/userProfileSlice';
import { getWorkspaces, useWorkspaces } from '@store/workspacesSlice';
import { getResources, useResources } from '@store/resourcesSlice';
import { getProduct, useProduct } from '@store/productSlice';
import { logger, whFgGnBg } from '@components/utilities/logger';
import { useLocalValue, setLocalValue } from '@store/localValuesSlice';

// eslint-disable-next-line import/prefer-default-export
export function useSmartReduxUpdates(enable: boolean) {
  const dispatch = useAppDispatch();
  const contentList = useContentList();
  const userProfile = useUserProfile();
  const workspaces = useWorkspaces();
  const resources = useResources();
  const productInformation = useProduct();
  const refreshData = useLocalValue<boolean>('refreshData');

  useEffect(() => {
    logger.info(...whFgGnBg('App appears to be reloading or loading for the first time, refreshing internal state'));
    setLocalValue('refreshData', true);
  }, []);

  useEffect(() => {
    if (!enable) {
      return;
    }
    // Only set back to false if true, otherwise we have a race condition with state updates.
    if (refreshData) {
      setLocalValue('refreshData', false);
    }
    const load = async () => {
      await Promise.all([
        (refreshData || !userProfile) && getUserProfileAsync(dispatch), // Exempt from refreshAll, see index.tsx.
        (refreshData || !contentList) && getContentList(dispatch),
        (refreshData || !workspaces) && getWorkspaces(dispatch),
        (refreshData || !resources) && getResources(dispatch),
        (refreshData || !productInformation) && getProduct(dispatch),
      ]);
    };
    load();
  }, [contentList, dispatch, enable, resources, productInformation, userProfile, workspaces, refreshData]);

  useEffect(() => {
    if (productInformation?.title && enable) {
      document.title = productInformation.title;
    }
  }, [productInformation, enable]);
}
