/* eslint-disable no-bitwise */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';

// Us
import UserProfile from '@models/UserProfile';
import Workspace from '@models/Workspace';
import { CustomTheme } from '@components/layout/Theme';
import Workspaces from './Workspaces';
import Settings from './Settings';

const StyledButton = styled(Button)(({ theme }: { theme: CustomTheme }) => ({
  '&': {
    textTransform: 'none',
    fontWeight: 'bold',
    height: '30px',
    alignSelf: 'center',
    color: theme.palette.common.white,
    marginRight: theme.spacing(0.5),
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hoverInverse,
    // borderRadius: '50%',
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }: { theme: CustomTheme }) => ({
  '&': {
    ...theme.typography.button,
    textTransform: 'none',
    fontWeight: 'bold',
    height: '30px',
    alignSelf: 'center',
    color: theme.palette.common.white,
    marginRight: theme.spacing(0.5),
    borderWidth: 0,
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: theme.palette.action.hoverInverse,
    // borderRadius: '50%',
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hoverInverse,
    // borderRadius: '50%',
  },
}));

interface OwnProps {
  workspaces?: Workspace[];
  onContentListClick?(): void;
  contentListToggleState: boolean;
  className?: string;
  userProfile?: UserProfile;
}

export default function TopNav(props: React.PropsWithChildren<OwnProps>) {
  const history = useHistory();
  const {
    workspaces, onContentListClick, className, contentListToggleState, userProfile,
  } = props;

  return (
    <>
      <div id="TopNav" className={className}>
        { userProfile?.isAdmin && (
          <StyledButton onClick={() => {
            history.push('/home');
          }}
          >
            Home
          </StyledButton>
        )}
        {(userProfile?.showContentList || userProfile?.isAdmin) ? (
          <StyledToggleButton value="depressed" selected={contentListToggleState} onClick={onContentListClick}>Content List</StyledToggleButton>
        ) : null }
        <Workspaces workspaces={workspaces} />
        { (userProfile?.isAdmin || userProfile?.sysConfigAdmin || userProfile?.sysUserAdmin || userProfile?.sysInstallAdmin || userProfile?.sysTroubleshootingAdmin || userProfile?.sysMetadataAdmin)
          && (
            <Settings
              showConfiguration={(userProfile?.sysConfigAdmin ?? false) || userProfile?.isAdmin}
              showUserAdministration={(userProfile?.sysUserAdmin ?? false) || userProfile?.isAdmin}
              showInstallationSettings={(userProfile?.sysInstallAdmin ?? false) || userProfile?.isAdmin}
              showTroubleshooting={(userProfile?.sysTroubleshootingAdmin ?? false) || userProfile?.isAdmin}
              showMetadataAdministration={(userProfile?.sysMetadataAdmin ?? false) || userProfile?.isAdmin}
            />
          )}
      </div>
    </>
  );
}
