/* eslint-disable no-unreachable */
// Them
import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { List, AutoSizer } from 'react-virtualized';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';
import StarIcon from '@mui/icons-material/Star';
import { Error } from '@mui/icons-material';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import Box from '@mui/material/Box';
import { unstable_composeClasses as composeClasses } from '@mui/base';
import {
  alpha, styled,
} from '@mui/material/styles';
import { treeItemClasses, getTreeItemUtilityClass } from '@mui/lab/TreeItem';
import rfdc from 'rfdc';

// Us
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { logger } from '@components/utilities/logger';
import { useAppDispatch } from '@store/hooks';
import { updateFavoriteAsync } from '@store/contentListSlice';
import ContentItem from '@models/ContentItem';
import ObjectType from '@models/ObjectType';
import { ContentListWidgetStateContext } from '@components/layout/ContentListWidgetState';
import { CustomTheme } from '@components/layout/Theme';
import { navContent } from '@components/utilities/Navigation';
import Icon from './Icon';
import FlattenedContentItem from './implementation/FlattenedContentItem';

export const enum ExpandStates {
  Indeterminate = 0, // Perform no action, component sets to this state after update.
  CollapseAll,
  ExpandAll,
}

interface OwnProps {
  contentList?: ContentItem[];
  filterPhrase: string;
  selectedTypes: Number[];
  includeSystemFolders: boolean;
  expandState: ExpandStates;
  setExpandState: (expandState: ExpandStates) => void;
}

const useUtilityClasses = (ownerState: any) => {
  const { classes } = ownerState;

  const slots = {
    root: ['root'],
    content: ['content'],
    expanded: ['expanded'],
    selected: ['selected'],
    focused: ['focused'],
    disabled: ['disabled'],
    iconContainer: ['iconContainer'],
    favorite: ['favorite'],
    label: ['label'],
    group: ['group'],
  };

  return composeClasses(slots, getTreeItemUtilityClass, classes);
};

const treeItemHorizPadding = 8;

const instanceTypes = ['WfInstance', 'FormInstance', 'ProjectInstance', 'CaseInstance'];

const helperClasses = {
  notFavorite: 'notFavorite',
  favoriteIcon: 'favoriteIcon',
  alertIcon: 'alertIcon',
};

// Much of this lifted from https://github.com/mui/material-ui/blob/v5.0.0/packages/mui-lab/src/TreeItem/TreeItem.js
// for consistency. Some (most) transitions won't work because we're not using <ul>/<li> to model true
// nesting and that's because we can't with a virtualized tree implemented on top of a list.
const StyledTreeItem = styled(Box)(({ theme }: { theme: CustomTheme }) => ({
  padding: `0 ${treeItemHorizPadding}px`,
  // Since we simulate <ul><li> (a tree), we need to allow the item to render the width we dictate.
  // width: '100%',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  WebkitTapHighlightColor: 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
  },
  [`&.${treeItemClasses.disabled}`]: {
    opacity: theme.palette.action.disabledOpacity,
    backgroundColor: 'transparent',
  },
  [`&.${treeItemClasses.focused}`]: {
    backgroundColor: theme.palette.action.focus,
  },
  [`&.${treeItemClasses.selected}`]: {
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
    [`&.${treeItemClasses.focused}`]: {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity + theme.palette.action.focusOpacity,
      ),
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    width: 15,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    paddingTop: 4,
    '& svg': {
      fontSize: 18,
    },
  },
  [`& .${treeItemClasses.label}`]: {
    ...theme.typography.body2,
    width: '100%',
    // fixes overflow - see https://github.com/mui-org/material-ui/issues/27372
    minWidth: 0,
    paddingLeft: 4,
    position: 'relative',
    fontSize: '14px',
  },
  [`& .${helperClasses.favoriteIcon}, .${helperClasses.alertIcon}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${helperClasses.favoriteIcon} svg, .${helperClasses.alertIcon} svg`]: {
    margin: 'auto',
  },
  [`& .${helperClasses.favoriteIcon}:hover svg`]: {
    fontSize: 22,
  },
  [`& .${helperClasses.notFavorite}`]: {
    visibility: 'hidden',
  },
  [`&:hover .${helperClasses.notFavorite}`]: {
    visibility: 'visible',
  },
}));

function flattenContentList(list: FlattenedContentItem[], indent: number, node: ContentItem, includeSystemFolders: boolean, expandState: ExpandStates, expanded: Map<string, boolean>) {
  if (!includeSystemFolders && (node.name.startsWith('[') && node.name.endsWith(']'))) {
    return;
  }

  let itemExpanded: boolean | undefined;
  if (Array.isArray(node.children)) {
    itemExpanded = expandState === ExpandStates.ExpandAll || (!!expanded?.size && !!expanded.get(node.id));
  } else {
    itemExpanded = undefined;
  }
  const flattened = { indent, contentItem: node, expanded: itemExpanded } as FlattenedContentItem;
  list.push(flattened);
  if (itemExpanded) {
    node.children.forEach((child: ContentItem) => flattenContentList(list, indent + 1, child, includeSystemFolders, expandState, expanded));
  }
}

function getSynthesizedChildren(node: FlattenedContentItem, includeSystemFolders: boolean): FlattenedContentItem[] {
  const filtered = includeSystemFolders ? node.contentItem.children : node.contentItem.children.filter((child: ContentItem) => (!child.name.startsWith('[') && !child.name.endsWith(']')));
  return filtered.map((child: ContentItem) => ({
    indent: node.indent + 1,
    contentItem: child,
    expanded: (Array.isArray(child.children) ? false : undefined),
  } as FlattenedContentItem));
}

function getExistingChildren(list: FlattenedContentItem[], index: number): FlattenedContentItem[] {
  const item = list[index];
  let offset = index + 1;
  for (; offset < list.length && list[offset].indent > item.indent; offset += 1);
  const count = offset - index - 1;

  return list.slice(index + 1, index + 1 + count);
}

function filterContentList(list: FlattenedContentItem[], indent: number, node: ContentItem, filterPhrase: string, selectedTypes: Number[], includeSystemFolders: boolean) {
  if (!includeSystemFolders && (node.name.startsWith('[') && node.name.endsWith(']'))) {
    return;
  }

  const priorLength = list.length;
  if (Array.isArray(node.children)) {
    node.children.forEach((child: ContentItem) => filterContentList(list, indent + 1, child, filterPhrase, selectedTypes, includeSystemFolders));
  }
  // filtering by type and name
  if (selectedTypes.length > 0 && filterPhrase.length > 0) {
    if (list.length > priorLength || (node.name.toLowerCase().includes(filterPhrase.toLowerCase()) && selectedTypes.includes(Number(ObjectType[node.type])))) {
      const flattened = { indent, contentItem: node, expanded: (Array.isArray(node.children) ? true : undefined) } as FlattenedContentItem;
      list.splice(priorLength, 0, flattened);
    }
    return;
  }
  // filtering by type
  if (selectedTypes.length > 0) {
    if (list.length > priorLength || selectedTypes.includes(Number(ObjectType[node.type]))) {
      const flattened = { indent, contentItem: node, expanded: (Array.isArray(node.children) ? true : undefined) } as FlattenedContentItem;
      list.splice(priorLength, 0, flattened);
    }
    return;
  }
  // filtering by name
  if (filterPhrase.length > 0) {
    if (list.length > priorLength || node.name.toLowerCase().includes(filterPhrase.toLowerCase())) {
      const flattened = { indent, contentItem: node, expanded: (Array.isArray(node.children) ? true : undefined) } as FlattenedContentItem;
      list.splice(priorLength, 0, flattened);
    }
  }
}

function usePrevious<T>(value: T) {
  const ref = React.useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// See https://stackoverflow.com/a/13382873/1455091.
function getScrollbarWidth(): number {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  // eslint-disable-next-line dot-notation
  (outer.style as any)['msOverflowStyle'] = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode!.removeChild(outer);

  return scrollbarWidth;
}

const scrollbarWidth = getScrollbarWidth();
const remWidth = parseFloat(getComputedStyle(document.documentElement).fontSize);

function searchRecursively(node: ContentItem, id: string): ContentItem | undefined {
  if (node.id === id) {
    return node;
  }
  let foundItem: ContentItem | undefined;
  if (node.children) {
    node.children.forEach((child) => {
      if (!foundItem) {
        foundItem = searchRecursively(child, id);
      }
    });
  }
  return foundItem;
}

function search(tree: ContentItem[], id: string): ContentItem | undefined {
  let foundItem: ContentItem | undefined;
  tree.forEach((node) => {
    if (!foundItem) {
      foundItem = searchRecursively(node, id);
    }
  });
  return foundItem;
}

export default function VirtualTree(props: React.PropsWithChildren<OwnProps>) {
  const {
    contentList, filterPhrase, selectedTypes, includeSystemFolders, expandState, setExpandState,
  } = props;
  // "State" (comes from our special context to avoid issues with component unmounting).
  const {
    open, pinned, list, filteredList, selectedId, setOpen, setList, setFilteredList,
  } = useContext(ContentListWidgetStateContext);
  const dispatch = useAppDispatch();
  // See https://stackoverflow.com/a/59843241/1455091. We use this to allow expand/collapse all
  // to function as desired.
  const previous = usePrevious([contentList, includeSystemFolders, expandState]);
  const prevSelectedId = usePrevious(selectedId);
  const activeList = (filterPhrase.length > 0 || selectedTypes.length > 0) ? filteredList : list;
  const history = useHistory();
  useEffect(() => {
    if (previous && previous[0] === contentList && previous[1] === includeSystemFolders && expandState === ExpandStates.Indeterminate && prevSelectedId === selectedId) {
      return;
    }
    const expanded = new Map<string, boolean>();
    switch (expandState) {
      case ExpandStates.ExpandAll:
        setExpandState(ExpandStates.Indeterminate);
        break;

      case ExpandStates.CollapseAll:
        setExpandState(ExpandStates.Indeterminate);
        break;

      case ExpandStates.Indeterminate:
        activeList.forEach((item) => { expanded.set(item.contentItem.id, !!item.expanded); });
        break;

      default:
    }
    const newList = new Array<FlattenedContentItem>();
    contentList?.forEach((node) => flattenContentList(newList, 0, node, includeSystemFolders, expandState, expanded));
    // Build path from root to selected element
    const path:string[] = [];
    if (contentList) {
      let item = search(contentList, selectedId);
      while (item) {
        const { parentId } = item;
        path.push(parentId);
        item = search(contentList, parentId);
      }
      path.reverse().forEach((parentId) => {
        const index = newList.findIndex((elem) => elem.contentItem.id === parentId);
        if (index !== -1 && newList[index] && !newList[index].expanded) {
          expandItem(newList, index);
        }
      });
    }
    setList(newList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentList, includeSystemFolders, expandState, prevSelectedId, selectedId, setExpandState]);

  const expandItem = useCallback((updatedList: FlattenedContentItem[], index: number) => {
    const item = updatedList[index];
    // Expanded but getting ready to collapse.
    if (item.expanded) {
      item.descendants = getExistingChildren(updatedList, index);
      updatedList.splice(index + 1, item.descendants.length);
    } else {
      // Collapsed but getting ready to expand.
      let count = item.descendants?.length ?? 0;
      if (!count) {
        item.descendants = getSynthesizedChildren(item, includeSystemFolders);
        count = item.descendants.length;
      }

      // If we reached out the max number of children, then we only add as many items as possible to the updatedList
      if (item.contentItem.maxLenChildren > 0 && item.contentItem.maxedOutMessage && item.descendants) {
        const newItems = new Array(item.contentItem.maxLenChildren).fill(null);
        for (let idx = 0; idx < item.contentItem.maxLenChildren; idx += 1) {
          newItems[idx] = item.descendants[idx];
        }
        updatedList.splice(index + 1, 0, ...newItems);
      } else {
        updatedList.splice(index + 1, 0, ...item.descendants!);
      }
    }
    item.expanded = !item.expanded;
    return updatedList;
  }, [includeSystemFolders]);

  const toggleExpanded = (index: number) => {
    const updatedList = rfdc()(activeList);
    expandItem(updatedList, index);
    if (filterPhrase) {
      setFilteredList(updatedList);
    } else {
      setList(updatedList);
    }
  };

  const toggleFavorite = async (index: number) => {
    const item = activeList[index];
    const contentItem = { ...item.contentItem, favorite: !item.contentItem.favorite };
    updateFavoriteAsync(dispatch, contentItem);
  };

  const getLabel = (content: ContentItem, className: string, width: number) => (
    <div className={className} style={{ width: `${width}px` }}>
      <Box
        onClick={() => {
          if (!pinned && open) {
            setOpen(false);
          }
          // Appending random string query param to 'trick' react-router and allow the user to go back to previous folder
          // in the TreeView if previous navigation was done by clicking a link within the Iframe
          navContent(history, content.url, { makeUnique: true });
        }}
      >
        {ObjectType[content.type.toString() as keyof typeof ObjectType] !== ObjectType.Folder && <Icon icon={content.icon} />}
        {content.name}
      </Box>
    </div>
  );

  useEffect(() => {
    if (filterPhrase || selectedTypes) {
      const newList = new Array<FlattenedContentItem>();
      contentList?.forEach((node) => filterContentList(newList, 0, node, filterPhrase, selectedTypes, includeSystemFolders));
      setFilteredList(newList);
    }
  }, [filterPhrase, selectedTypes, contentList, includeSystemFolders, setFilteredList]);

  const rowRenderer = ({ index, style, widthParent }: { index: number, style: any, widthParent: number}) => {
    const item = activeList[index];
    const ownerState = {
      ...props,
      expanded: item.expanded,
      focused: false,
      selected: false,
      disabled: false,
    };
    // We need to strip width because we manually indent each row item and the width causes
    // undesired horizontal scrolling that varies as the list scrolls vertically. That's
    // because the list items are dynamically added/removed during vertical scrolls.
    const { width: _, ...styles } = style;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const classes = useUtilityClasses(ownerState);
    let expandIcon = <></>;
    if (item.expanded === true) {
      // See <StyledTreeItem> for aria-related items.
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      expandIcon = <div className={classes.iconContainer} onClick={() => toggleExpanded(index)} onKeyPress={() => toggleExpanded(index)}><ExpandMoreIcon /></div>;
    } else if (item.expanded !== undefined) {
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      expandIcon = <div className={classes.iconContainer} onClick={() => toggleExpanded(index)} onKeyPress={() => toggleExpanded(index)}><ExpandLessIcon /></div>;
    }

    const indent = (item.indent + (item.expanded !== undefined ? 0 : 1)) * remWidth;
    let width = widthParent - indent - scrollbarWidth - treeItemHorizPadding * 2;
    // Prevent narrow tree windows or excessive item nesting from creating unviewable tree items.
    if (width < 0.5 * widthParent) { width = 0.5 * widthParent; }
    const isHighlighted = item.contentItem.id === selectedId;
    const isInstance = instanceTypes.includes(item.contentItem.type.toString());

    let favoriteIcon = <></>;
    if (!isInstance) {
      const icon = (item.contentItem.favorite ? <StarIcon /> : <StarBorderOutlinedIcon />);
      let classNames = `${classes.iconContainer} ${helperClasses.favoriteIcon}`;
      if (!item.contentItem.favorite) {
        classNames = `${classNames} ${helperClasses.notFavorite}`;
      }
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      favoriteIcon = <div className={classNames} onClick={() => toggleFavorite(index)} onKeyPress={() => toggleFavorite(index)}>{icon}</div>;
    }

    let alertIcon = <></>;
    let tooltipoMaxedOut = '';
    if (item.contentItem.maxLenChildren > 0 && item.contentItem.maxedOutMessage) {
      const classNames = `${classes.iconContainer} ${helperClasses.alertIcon}`;
      alertIcon = <div className={classNames} style={{ marginLeft: 5 }}><Error /></div>;
      tooltipoMaxedOut = item.contentItem.maxedOutMessage ? item.contentItem.maxedOutMessage : '';
    }

    let tooltipModified = '';
    if (item.contentItem.modified !== undefined) {
      try {
        const modified = new Date(Date.parse(item.contentItem.modified));
        tooltipModified = modified.toLocaleString();
      } catch {
        // eslint-disable-next-line no-console
        console.error(`Invalid value in Modified field for Item "${item.contentItem.name}": ${item.contentItem.modified}`);
      }
    }

    return (
      <StyledTreeItem
        key={item.contentItem.id}
        style={{}} // This silences a MUI warning about overriding styles.
        sx={{
          ...styles,
          left: `${indent}px`,
          width: `${width}px`,
          backgroundColor: isHighlighted ? '#EEEEEE' : null,
          color: isInstance ? '#797979' : 'black',
        }}
        whiteSpace="nowrap"
        role="treeitem"
        tabIndex={-1}
      >
        {expandIcon}
        <Tooltip
          title={(
            <span>
              {item.contentItem.name}
              <br />
              {tooltipModified}
              <br />
              {tooltipoMaxedOut}
            </span>
          )}
        >
          {getLabel(item.contentItem, classes.label, width - (item.expanded !== undefined ? 32 : 0))}
        </Tooltip>
        {favoriteIcon}
        {alertIcon}
      </StyledTreeItem>
    );
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          width={width}
          height={height}
          rowCount={activeList.length}
          rowHeight={24}
          rowRenderer={({ index, style }: { index: number, style: any}) => rowRenderer({ index, style, widthParent: width })}
          overscanRowCount={2}
          role="tree"
          ariaMultiselectable="false"
          tabIndex={0}
        />
      )}
    </AutoSizer>
  );
}
