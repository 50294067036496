// Them
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Divider from '@mui/material/Divider';
import { makeStyles, createStyles } from '@mui/styles';

// Us
import { CustomTheme } from '@components/layout/Theme';
import UserProfile from '@models/UserProfile';
import { logoff } from '@components/data/NetworkMonitor';
import { getCookie } from '@/components/utilities/Cookies';
import Workspace from '@/models/Workspace';
import { useDispatch } from 'react-redux';
import { setActiveWorkspaceAsync, useWorkspaces } from '@/store/workspacesSlice';

const useStyles = makeStyles((theme: CustomTheme) => createStyles({
  profileInfo: {
    margin: '1rem',
  },
  listItemIcon: {
    minWidth: '2rem',
  },
}));

interface Props {
  anchorEl?: Element | null | undefined;
  open?: boolean;
  onClose?(event: object): any;
  userProfile: UserProfile;
  workspaces: Workspace[];
}

const debugCookieName = 'BP_Debug';

const getDebugMode = (): boolean => (getCookie(debugCookieName) || '').length !== 0;

const toggleDebugMode = (value:boolean): void => {
  if (value) {
    document.cookie = `${debugCookieName}=1;path=/`;
  } else {
    document.cookie = `${debugCookieName}=''; expires=Fri, 31 Dec 1999 23:59:59 GMT;path=/`;
  }
};

export default function ProfileMenu(props: Props) {
  const [currentDebugMode, setCurrentDebugMode] = useState<boolean>(getDebugMode());
  const dispatch = useDispatch();

  const safeClose = () => {
    if (onClose) { onClose((<></>)); }
  };

  const first = useWorkspaces()[0];

  const leaveCase = () => {
    setActiveWorkspaceAsync(dispatch, first.id);
    safeClose();
  };

  const handleLogoff = () => {
    safeClose();
    logoff();
  };

  const ToggleDebugMode = async () => {
    toggleDebugMode(!currentDebugMode);
    setCurrentDebugMode(!currentDebugMode);
    safeClose();
  };

  const {
    anchorEl, onClose, open, userProfile, workspaces,
  } = props;
  const classes = useStyles();

  // If you touch code in this file, you need to also look at LayoutMobile.tsx too for mobile devices.
  const getUserName = (): string => {
    if (userProfile.userName && userProfile.userId) {
      return `${userProfile.userName} (${userProfile.userId})`;
    } if (userProfile.userName) {
      return userProfile.userId;
    } if (!userProfile.userName && userProfile.userId) {
      return userProfile.userId;
    }
    return '(Not available)';
  };

  const activeWorkspace = workspaces.find((w) => w.isActive);

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      PaperProps={{ style: { minWidth: '20rem' } }}
    >
      <div className={classes.profileInfo}>
        <Typography noWrap>
          {getUserName()}
        </Typography>
        <Typography color="textSecondary" noWrap>
          {userProfile.emailAddress}
        </Typography>
      </div>
      <Divider />
      {(userProfile.showEdit !== false) && (
        <Link to="/if/user_profile.aspx" onClick={safeClose} style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem>
            <ListItemIcon className={classes.listItemIcon}>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Profile</Typography>
          </MenuItem>
        </Link>
      )}
      {(activeWorkspace?.isOpenCase) && (
        <Link to={`/if/workspace.aspx?CLOSECASE=${activeWorkspace?.id}`} onClick={leaveCase} style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem>
            <ListItemIcon className={classes.listItemIcon}>
              <WorkIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{ activeWorkspace?.leaveCaseString }</Typography>
          </MenuItem>
        </Link>
      )}
      {(userProfile.canDebug) && (
        <MenuItem onClick={() => ToggleDebugMode()}>
          <ListItemIcon className={classes.listItemIcon}>
            <BugReportOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Debug Mode:
            {(currentDebugMode ? ' ON' : ' OFF')}
          </Typography>
        </MenuItem>
      )}
      {userProfile.canImpersonate && (
        <Link to="/if/admin/impersonate.aspx?notabs=1" onClick={safeClose} style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem>
            <ListItemIcon className={classes.listItemIcon}>
              <SupervisedUserCircleIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Impersonate User</Typography>
          </MenuItem>
        </Link>
      )}
      {(userProfile.showLogout !== false) && (
        <MenuItem onClick={() => handleLogoff()}>
          <ListItemIcon className={classes.listItemIcon}>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Logout
          </Typography>
        </MenuItem>
      )}
    </Menu>
  );
}
