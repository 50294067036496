// Them
import React, { useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router';
import Box from '@mui/material/Box';
import { useTheme, makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';

// Us
import { logger } from '@components/utilities/logger';
import { CustomTheme, useResponsiveToolbarHeight } from '@components/layout/Theme';
import { reactPath } from '@components/utilities/Paths';
import PageTitle from '@components/utilities/PageTitle';
import { ContentListWidgetStateContext } from '@components/layout/ContentListWidgetState';
import { useDispatch } from 'react-redux';
import { getWorkspaces, setActiveWorkspaceAsync } from '@/store/workspacesSlice';

const iframeContainerMargin = '483';

const useStyles = makeStyles((customTheme: CustomTheme) => ({
  toolbar: {
    // necessary for content to be below app bar
    ...customTheme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: customTheme.spacing(0, 1),
  },
  content: {
    position: 'relative',
    flexGrow: 1,
  },
}));

interface RouteParams {
  destUrl: string;
}

export default function ContentFrame() {
  const { destUrl } = useParams<RouteParams>();
  const toolbarHeight = useResponsiveToolbarHeight();
  const location = useLocation();
  const [title, setTitle] = useState('');
  const { open, setSelectedId } = useContext(ContentListWidgetStateContext);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const boxStyle = isMobile
    ? {
      overflow: 'auto',
      height: `calc(100vh - ${toolbarHeight}px)`,
    } : {
      overflow: open ? 'hidden auto' : 'auto',
      marginLeft: open ? `${iframeContainerMargin}px` : '',
      height: `calc(100vh - ${toolbarHeight}px)`,
    };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Box sx={{ ...boxStyle }}>
        <PageTitle title={title}>
          <iframe
            title="content"
            src={`${reactPath}/../${destUrl}${location.search}`}
            onLoad={(event) => {
              const params = new URLSearchParams(event.currentTarget.contentWindow?.location.search);
              let newElement: string | null = null;
              const kvid = params.get('kvid');
              setTitle(event.currentTarget.contentWindow?.document.title ?? '');
              if (params.get('caseinstid')) {
                setActiveWorkspaceAsync(dispatch, params.get('caseinstid') ?? '');
              }
              const params2 = new URLSearchParams(location.search);
              if (params2.get('CLOSECASE')) {
                getWorkspaces(dispatch);
              }
              switch (kvid) {
                case '(FORM_INSTANCES)':
                case '(CASE_INSTANCES)':
                case '(WF_A_INSTANCES)':
                case '(PR_A_INSTANCES)':
                  newElement = `a_${params.get('id')}`;
                  break;
                case '(PR_I_INSTANCES)':
                case '(WF_I_INSTANCES)':
                  newElement = `i_${params.get('id')}`;
                  break;
                default:
                  newElement = params.get('id');
              }
              setSelectedId(newElement ?? '');
              logger.debug(`ContentFrame onLoad completed: ${newElement}`);
            }}
            style={{
              width: '100%',
              height: `calc(100vh - ${toolbarHeight}px)`,
              border: 'none',
              display: 'flex',
            }}
          />
        </PageTitle>
      </Box>
    </main>
  );
}
