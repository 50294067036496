// Them
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Us
// eslint-disable-next-line import/no-cycle
import { RootState, AppDispatch } from '@store/store';
import { useAppSelector } from '@store/hooks';
// eslint-disable-next-line import/no-cycle
import { getAsync } from '@components/data/rest';
import ProductInformation from '@/models/ProductInformation';

export interface ProductState {
  value: ProductInformation;
}

const initialState: ProductState = {
  value: {} as ProductInformation,
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    store: (state, action: PayloadAction<ProductInformation>) => {
      state.value = action.payload;
    },
  },
});

export const { store } = productSlice.actions;

export const getProduct = async (dispatch: AppDispatch) => {
  await getAsync('Product',
    async (response) => {
      dispatch(store(await response.obj<ProductInformation>()));
    });
};

export const useProduct = () => useAppSelector((state: RootState) => state.product.value);

export default productSlice.reducer;
